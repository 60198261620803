import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Tooltip,
} from "@mui/material";

import { FetchExperimentDuration } from "./../helpers/FetchData";
import CenteredSpinner from "./../components/common/CenteredSpinner";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { ApiResponse } from "../types/Types";
import ExperimentDurationGraph from "./ExperimentDurationGraph";
import ColorPalette from "../helpers/ColorPalette";

interface DurationData {
  metrics: [];
  message: string | null;
}

const DurationVariation = (metric: any, key: any) => {
  if (!metric.days_num || metric.days_num!.length < 5) {
    return (
      <TableRow key={key}>
        <TableCell>{metric.name}</TableCell>
        <TableCell colSpan={5}>Insuffucient data</TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow key={key}>
      <TableCell>{metric.name}</TableCell>
      <TableCell>{Intl.NumberFormat().format(metric.days_num[0])}</TableCell>
      <TableCell>{Intl.NumberFormat().format(metric.days_num[1])}</TableCell>
      <TableCell>{Intl.NumberFormat().format(metric.days_num[2])}</TableCell>
      <TableCell>{Intl.NumberFormat().format(metric.days_num[3])}</TableCell>
      <TableCell>{Intl.NumberFormat().format(metric.days_num[4])}</TableCell>
    </TableRow>
  );
};

const MdeData: React.FC<any> = ({ durationData }) => {
  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <TableContainer
        className="mde-table"
        sx={{
          "& .MuiTableCell-root": { fontSize: "0.675rem" },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow sx={{ "& .MuiTableCell-root": { borderBottom: 0 } }}>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                Number of days to reach:
              </TableCell>
            </TableRow>
            <TableRow sx={{ borderTop: 0 }}>
              <TableCell>Metric</TableCell>
              <TableCell>1%</TableCell>
              <TableCell>2%</TableCell>
              <TableCell>3%</TableCell>
              <TableCell>4%</TableCell>
              <TableCell>5%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ "& tr:last-of-type .MuiTableCell-root": { borderBottom: 0 } }}
          >
            {durationData.metrics.map((metric: any, key: any) => (
              <DurationVariation key={key} {...metric} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const ExperimentDuration: React.FC<any> = ({ rule, isDisabled }) => {
  const [open, setOpen] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);
  const [durationData, setDurationData] = useState<DurationData>({
    message: "(This operation can take up to a minute.)",
    metrics: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [chartColors, setChartColors] = useState<any>([]);
  const [isWide, setisWide] = useState<any>(false);

  useEffect(() => {
    setDurationData({
      message: "(This operation can take up to a minute.)",
      metrics: [],
    });
    const successMetrics = rule.metrics.filter(
      (metric: any) => metric.is_success
    );
    setisWide(successMetrics.length > 1);
  }, [rule]);

  const getDuration = () => {
    setIsLoading(true);
    FetchExperimentDuration((output: ApiResponse) => {
      if (output?.status !== 200) {
        setDurationData({
          message:
            "Duration couldn't be calculated, please check your experiment configuration",
          metrics: [],
        });
      } else {
        setDurationData(output.response);
        const palette = ColorPalette(output.response.metrics.length);
        const colors: any = [];
        output.response.metrics.forEach((metric: any, idx: number) => {
          colors[idx] = palette(idx);
        });
        setChartColors(colors);
      }
      setIsLoading(false);
    }, rule.id);
  };

  const toggleOpen = () => {
    const hasValidLocale = rule.attributes.some(
      (e: any) => e.attribute === "locale"
    );
    const hasValidTouchpoint = rule.attributes.some(
      (e: any) => e.attribute === "touchpoint"
    );
    const hasSuccessMetric = rule.metrics.some((e: any) => e.is_success);

    if (hasValidLocale && hasValidTouchpoint && hasSuccessMetric) {
      setOpen(true);
    } else {
      setErrorOpen(true);
    }
  };

  return (
    rule.meta &&
    rule.meta.version >= 4 && (
      <>
        <Tooltip title="Estimate the number of days to reach statistical significance on your success metrics">
          <IconButton disabled={isDisabled} onClick={toggleOpen}>
            <HourglassEmptyIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth={true}
          maxWidth={isWide ? "md" : "sm"}
        >
          <DialogTitle>
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography variant="h6">Experiment duration</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={{
              pt: "10px!important",
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="body2">
                  This tool will help you estimate the number of days to reach
                  statistical significance on your success metrics, based on
                  your locales, toucpoints and the experiment page.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                sx={{ pl: 1, pr: 2 }}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getDuration()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {isLoading ? (
                <CenteredSpinner />
              ) : durationData.metrics.length < 1 ? (
                <Typography style={{ fontSize: "small", fontStyle: "italic" }}>
                  {durationData.message}
                </Typography>
              ) : (
                <Grid container spacing={2}>
                  {durationData.metrics.map((metric: any, key: any) => (
                    <Grid item xs={isWide ? 6 : 12} key={key}>
                      <ExperimentDurationGraph
                        data={metric}
                        color={chartColors[key]}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <MdeData durationData={durationData} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={errorOpen} onClose={() => setErrorOpen(false)}>
          <DialogContent sx={{ p: "30px" }}>
            <IconButton
              onClick={() => setErrorOpen(false)}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body2">
              Your experiment must have the following to calculate the duration:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  At least one audience with the locale attribute
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  At least one audience with the touchpoint attribute
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  At least one success metric
                </Typography>
              </li>
            </ul>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default ExperimentDuration;
