import { enqueueSnackbar } from "notistack";
import { publicClientApplication } from "./Auth";
import { jwtDecode } from "jwt-decode";

export function getAggField(metric: {
  aggregator: string;
  scope: string;
  field: string;
}): string {
  let heading;
  let heading2;
  let heading3;

  if (metric.aggregator === "sum" || metric.aggregator === "count") {
    heading = "Total";
  } else {
    heading = "Unique";
  }

  if (metric.scope === "visitor") {
    heading3 = "Visitors";
  } else if (metric.scope === "event") {
    heading3 = "Conversions";
  }

  if (metric.field === "revenue") {
    heading2 = "Revenue";
  } else if (metric.field === "value") {
    heading2 = "Value";
  } else {
    heading2 = "Conversions";
  }
  return heading + " " + heading2 + " " + heading3;
}

export const evenDistributedAllocataion = (nrOfVariations: number) => {
  let evenPerVariation = Number((100 / nrOfVariations).toFixed(2));

  let arr = [];
  let full = 100;

  for (let i = 1; i <= nrOfVariations; i++) {
    full = full - evenPerVariation;
    if (i === nrOfVariations) {
      if (full !== 0) {
        evenPerVariation = Number(
          (evenPerVariation + Number(full.toFixed(2))).toFixed(2)
        );
      }
    }
    arr.push(Number(evenPerVariation));
  }
  return arr;
};

export const getDefaultType = () => {
  const type = localStorage.getItem("fabulousType");
  return type || "0";
};

export const setDefaultType = (type: string) => {
  localStorage.setItem("fabulousType", type);
};

export const getDefaultTeam = () => {
  const storedId = localStorage.getItem("fabulousTeamId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultTeam = (teamId: Number) => {
  localStorage.setItem("fabulousTeamId", JSON.stringify(teamId));
};

export const setDefaultBrand = (brandId: Number) => {
  localStorage.setItem("fabulousBrandId", JSON.stringify(brandId));
};

export const getDefaultBrand = () => {
  const storedId = localStorage.getItem("fabulousBrandId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultState = (stateId: Number) => {
  localStorage.setItem("fabulousStateId", JSON.stringify(stateId));
};

export const getDefaultState = () => {
  const storedId = localStorage.getItem("fabulousStateId");
  return storedId ? JSON.parse(storedId) : -1;
};

export const getDefaultRegion = () => {
  const storedId = localStorage.getItem("fabulousUserLatestRegion");
  return storedId ? storedId : "0";
};

export const setDefaultRegion = (region_key: string) => {
  localStorage.setItem("fabulousUserLatestRegion", region_key);
};
export const getDefaultHasLive = () => {
  const check = localStorage.getItem("fabulousUserHasLive");
  return check ? JSON.parse(check) : false;
};

export const setDefaultHasLive = (check: boolean) => {
  localStorage.setItem("fabulousUserHasLive", JSON.stringify(check));
};
export const isLiveValid = (ruleData: any, state: number) => {
  let error = false;
  const messages: any = [];

  // Locale is mandatory for all states
  if (!ruleData.attributes.some((e: any) => e.attribute === "locale")) {
    messages.push("At least one audience with the locale attribute");
    error = true;
  }

  if (!ruleData.attributes.some((e: any) => e.attribute === "touchpoint")) {
    messages.push("At least one audience with the touchpoint attribute");
    error = true;
  }

  // Variations, touchpoints, hypothesis and success metrics are mandatory for state 30 and 20
  if (state === 30 || state === 20) {
    if (ruleData.type !== "rollout" && ruleData.variations.length < 2) {
      messages.push("At least two variations");
      error = true;
    }

    if (
      ruleData.type !== "rollout" &&
      !ruleData.metrics.some((e: any) => e.is_success)
    ) {
      messages.push("At least one success metric");
      error = true;
    }
    if (ruleData.hypothesis.includes("state the change on the website/app")) {
      messages.push("A valid hypothesis");
      error = true;
    }

    if (ruleData.meta && ruleData.meta.version >= 1 && !ruleData.region_key) {
      if (!ruleData.owner || ruleData.owner.length < 3) {
        messages.push("Name of the owner");
        error = true;
      }

      if (!ruleData.developer || ruleData.developer.length < 3) {
        messages.push("Name of the developer");
        error = true;
      }

      if (ruleData.type !== "rollout" && (state === 30 || state === 20)) {
        if (!ruleData.planned_end_date) {
          messages.push("Planned end date");
          error = true;
        }
        if (!ruleData.planned_start_date) {
          messages.push("Planned start date");
          error = true;
        }
      }
    }
  }
  if (error) {
    const output = "The following is missing: \r\n• " + messages.join("\r\n• ");
    enqueueSnackbar(output, {
      variant: "error",
      className: "snackbar-state-error",
    });
  }
  return !error;
};

export const validToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const now = Math.round(Date.now() / 1000);
    if (decoded.exp && decoded.exp > now) {
      return true;
    } else {
      sessionStorage.setItem("fabulousTokenExpired", JSON.stringify(true));
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const redirectLogin = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["openid"],
    account: account,
  };
  publicClientApplication.acquireTokenRedirect(accessTokenRequest);
};

export const signOut = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  publicClientApplication.logoutRedirect({
    postLogoutRedirectUri: "/",
    account: account,
  });
};

export const getRedirectUri = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
};

export const formatAudienceMatchType = (matchtype: any) => {
  let out;
  switch (matchtype) {
    case "eq":
      out = "is";
      break;
    case "ne":
      out = "is not";
      break;
    case "sub":
      out = "contains";
      break;
    case "nosub":
      out = "excludes";
      break;
    case "gt":
      out = ">";
      break;
    case "gte":
      out = ">=";
      break;
    case "lt":
      out = "<";
      break;
    case "lte":
      out = "<=";
      break;
    default:
      break;
  }
  return `${out}`;
};

export const isTargetingApps = (ruleData: any) => {
  const touchpointValues = ruleData.attributes
    .filter((audience: any) => audience.attribute === "touchpoint")
    .flatMap((audience: any) => audience.values);
  return (
    touchpointValues.includes("ios") || touchpointValues.includes("android")
  );
};
