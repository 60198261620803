import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Alert,
  Chip,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
  Tooltip,
  Switch,
  FormControlLabel,
  FormControl,
  List,
  ListItem,
} from "@mui/material";

import { useConfig } from "../context";

import { FetchExpermentStartEvents } from "./../helpers/FetchData";

import CloseIcon from "@mui/icons-material/Close";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { ApiResponse } from "../types/Types";

const VerifyStartEvents: React.FC<any> = ({ rule, brandId, isDisabled }) => {
  const [open, setOpen] = useState(false);

  const [experimentStartEvents, setExperimentStartEvents] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [getQa, setGetQa] = useState(false);
  const { config } = useConfig();
  const getDuration = () => {
    setIsLoading(true);
    setHasFetched(false);

    FetchExpermentStartEvents(
      (output: ApiResponse) => {
        if (output?.status !== 200) {
          setExperimentStartEvents({});
        } else {
          if (Object.keys(output.response).length < 1) {
            setExperimentStartEvents({});
          } else {
            setExperimentStartEvents(output.response);
          }
        }
        setIsLoading(false);
        setHasFetched(true);
      },
      rule.id,
      getBrandKey(brandId),
      getQa
    );
  };

  const getBrandKey = (brandId: String) => {
    const brand: any = config?.brands?.find(
      (brand: any) => brand.id === brandId
    );
    const result = brand ? brand.brand_key : "unknown";
    return result;
  };

  const handleClose = () => {
    setHasFetched(false);
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Verify start events">
        <IconButton disabled={isDisabled} onClick={() => setOpen(true)}>
          <SportsScoreIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item xs={12}>
              <Typography variant="h6">Verify start events</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: "30vh",

            pt: "10px!important",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="body2">
                This function can be used to verify that epxeriment start events
                are being sent properly. It will show the number of start events
                captured for each variation of the experiment during the last 30
                minutes. The start events are captured in:
              </Typography>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                <ListItem sx={{ display: "list-item", fontSize: "0.875rem" }}>
                  The <strong>production</strong> environments for experiments
                  in the Live state or QA state forced with the FEP Tool.
                </ListItem>
                <ListItem sx={{ display: "list-item", fontSize: "0.875rem" }}>
                  The <strong>Test apps</strong> and/or <strong>INT</strong>{" "}
                  environments, use the QA toggle below
                </ListItem>
              </List>
              <Alert severity="info" sx={{ mt: 2 }}>
                There may be a delay for up to 5 minutes for the events to
                appear. Make sure to wait a few minutes before checking.
              </Alert>
            </Grid>

            <Grid
              item
              xs={6}
              container
              sx={{ pl: 1, pr: 2 }}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => getDuration()}
              >
                Check
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              sx={{ pl: 1, pr: 2 }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={4} maxWidth="70px!important">
                <FormControl>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={getQa}
                        onChange={() => setGetQa(!getQa)}
                        size="small"
                        color="warning"
                        sx={{ mt: "-10px" }}
                      />
                    }
                    label={
                      <Typography sx={{ paddingBottom: 1 }} variant="caption">
                        <strong>QA</strong>
                      </Typography>
                    }
                    labelPlacement="top"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="caption"
                  display="inline-block"
                  lineHeight={1.2}
                  fontStyle={"italic"}
                >
                  Use QA to capture events from the test apps or the INT
                  environment.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            {/*<Alert severity="warning">No data found.</Alert> */}
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell>Variation</TableCell>
                  <TableCell>Number of Events</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rule.variations.map((variation: any, key: any) => (
                  <TableRow key={key} sx={{ height: "60px" }}>
                    <TableCell>{variation.name}</TableCell>
                    <TableCell>
                      {isLoading ? (
                        <CircularProgress color="inherit" size="18px" />
                      ) : hasFetched ? (
                        <Chip
                          size="small"
                          label={experimentStartEvents[variation.id] || 0}
                          color={
                            experimentStartEvents[variation.id] > 0
                              ? "success"
                              : "error"
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyStartEvents;
