import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import { useTheme } from "@mui/material/styles";

const ExperimentDurationGraph: React.FC<any> = (props) => {
  const { data, color } = props;
  const theme = useTheme();

  const tooltipStyle = {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    fontSize: 12,
  };

  const tooltipFormatter = (value: any, name: any) => {
    return [Intl.NumberFormat().format(value), "Days"];
  };

  const labelFormatter = (value: any, name: any) => {
    return ["MDE: " + value];
  };

  const tickFormatter = (tick: any) => {
    return Intl.NumberFormat().format(tick);
  };
  useEffect(() => {
    if (!data.days_num || data.days_num!.length < 1) return;

    const chartData = data.days_num.map((item: any, index: any) => {
      return {
        mde: index + 1 + "%",
        days: item,
      };
    });
    setChartData(chartData);
  }, [data]);
  const [chartData, setChartData] = useState<any>([]);

  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <CardContent>
        <Typography variant="h6">{data.name}</Typography>
        {!data.days_num || data.days_num!.length < 1 ? (
          <Box
            height={300}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DoDisturbIcon color="disabled" sx={{ fontSize: 140 }} />
            <Typography variant="overline">No data available</Typography>
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mde" height={40} tick={{ fontSize: "0.775rem" }}>
                <Label
                  value="MDE"
                  position="insideBottom"
                  fontSize="0.775rem"
                />
              </XAxis>
              <YAxis
                tickFormatter={tickFormatter}
                tick={{ fontSize: "0.775rem" }}
              >
                <Label
                  value="Days"
                  angle={-90}
                  position="insideLeft"
                  fontSize="0.775rem"
                  offset={0}
                />
              </YAxis>
              <Tooltip
                formatter={tooltipFormatter}
                labelFormatter={labelFormatter}
                contentStyle={tooltipStyle}
                itemStyle={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                cursor={{ fill: "#b8e18f", fillOpacity: 0.3 }}
              />
              <Line type="monotone" dataKey="days" stroke={color} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default ExperimentDurationGraph;
