const baseSiteWideBanner = {
  enableSiteWideBannerApp: true,
  enableSiteWideBannerWeb: true,
  dropDownId: "site-wide-banner-dropdown",
  endDate:
    new Date(new Date().setDate(new Date().getDate() + 7))
      .toISOString()
      .split(".")[0] + "Z",
  labels: {
    headline: "Headline",
    legalText: "Legal text",
    expandedCta: "Close this",
    collapsedCta: "Shop now",
  },
  launchDate: "2024-09-12T07:18:00Z",
  legalTextPlacement: "expanded",
  links: [
    {
      aliasPath: "/it_it/donna.html",
      path: "/it_it/ladies.html",
      targetPath: "/content/hmonline/it_it/ladies",
      targetTemplate: "/apps/hm/templates/content/department",
      title: "Donna",
      trackingActivityCode: "fabulous-abtest-sitewidebanner-cta",
      trackingActivityType: "sitewidebanner",
    },
    {
      aliasPath: "/it_it/uomo.html",
      path: "/it_it/men.html",
      targetPath: "/content/hmonline/it_it/men",
      targetTemplate: "/apps/hm/templates/content/department",
      title: "Uomo",
      trackingActivityCode: "fabulous-abtest-sitewidebanner-cta",
      trackingActivityType: "sitewidebanner",
    },
  ],
  palette: "inverted",
  shouldShowCta: true,
  triggerId: "site-wide-banner-trigger",
  trackingActivityCode: "fabulous-abtest-sitewidebanner",
  trackingActivityType: "sitewidebanner",
};

export default baseSiteWideBanner;
